import Card from "./components/Card";
import Header from "./components/Header";

const App = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-300">
      <Header />
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <Card />
        </div>
      </div>
    </div>
  );
};

export default App;
