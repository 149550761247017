const CardBody = ({ title, username, content, pic }) => {
  console.log(pic);
  return (
    <div className="flex">
      <div>
        <h4 className="text-lg font-bold">
          {username} - {title}
        </h4>
        <p className="mt-1">{content}</p>
      </div>
    </div>
  );
};

export default CardBody;
