import React, { useState, useEffect } from "react";
import CardBody from "./CardBody";

const Card = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await fetch("https://cloudflare.onlinefiler.workers.dev/post", {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          setPosts(result);
        });
    };

    fetchData();
  }, []);

  return (
    <ul className="space-y-3">
      {posts.map((post, idx) => (
        <li
          key={idx}
          className="bg-gray-50 shadow overflow-hidden rounded-md px-6 py-4"
        >
          <CardBody {...post} />
        </li>
      ))}
    </ul>
  );
};

export default Card;
