import React from "react";

const Header = () => {
  return (
    <div>
      <h1 className="font-weight-light display-4 text-5xl text-center mb-4">
        Cloudflare Workers Requests
      </h1>
    </div>
  );
};

export default Header;
